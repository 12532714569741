.footer {
	position: relative;
	background-color: #fafafa;
	padding: 12px 20px;

	.logo {
		margin-bottom: 0;
	}

	.clearbit {
		position: relative;
		bottom: 0;
		right: 0;

		a {
			font-size: 12px;
		}
	}
}