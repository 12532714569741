@import "../../variables";

.section-heading {
  padding: 1rem 0 1.2rem 0;

  h2 {
		font-size: 2.75rem;
		font-weight: 700;
  }
}

// animation
.text-animation-appear {
  opacity: 0.01;
  &.heading-animation-appear-active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
}
