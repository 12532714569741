@import "../../variables";

.go-top {
  position: fixed;
  right: 20px;
  bottom: 73px;
  z-index: 999;
  cursor: pointer;
  box-shadow: $box-shadow;
  transition: $transition;
  background: $red;
  opacity: 0.7;

  &:hover {
    box-shadow: $box-shadow-hover;
    transform: scale(1.1);
		opacity: 1;
		animation: bounce 1s;
  }
}

// arrow up
.gg-arrow-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid $red;
  transform: scale(var(--ggs, 1.2));
  border-radius: 4px;
  color: white;
}

.gg-arrow-up-r::after,
.gg-arrow-up-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}

.gg-arrow-up-r::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
}

.gg-arrow-up-r::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

// bounce to top
// .bounce {
//   animation: bounce 2s;
// }
@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-4px);
  }
}
