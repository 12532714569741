@import "./variables";

body {
	background-color: #eff0f3;
  font-size: 1rem;
  color: #2b2a27;
  // color: #c38375;
  font-family: 'Roboto Mono', sans-serif;
}

main {
	padding: 0 12px 24px 12px;
	min-height: 92vh;
}

button {
	font-family: 'Roboto Mono', sans-serif;	
}

// 404 page not found
.not-found {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	.not-found--inner {
		text-align: center;
	}
}


// screen reader
.screen-reader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border: 0;
}

.visually-hidden { 
	position: absolute !important;
	height: 1px; 
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap; /* added line */
}


// external link image arrow
.external-link {
	display: none;
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 16px;
	height: 16px;
	transition: $transitionSlower;

	&__icon {
		stroke: rgba(3,42,60,.6);
		stroke-width: 1.1;
	}
}


// posts grid like button
.like-button-wrap {
	position: absolute;
	right: 1.25rem;
	top: 1.25rem;
}

.like-button-wrap button {
	font-size: 12px;
	border: 1px solid #c4c4c4;
}