$white: rgba(255, 255, 255, 1);
$whiteLighter: rgba(255, 255, 255, 0.6);
$dark: #10171d;
$darkLighter: #1b2938;
$grey: #dfdfdf;
$moonColor: #52b2d2;

// light mode
body.light-mode {
  color: $darkLighter;
  transition: background-color 0.3s ease;
}

// dark mode
body.dark-mode {
  background-color: $dark;
	color: $grey;
	
  h2 {
    color: $white;
	}
	
  aside {
    background-color: $darkLighter;

    .item__name {
      color: $white;
    }
	}
	
	.logo .waves {
    stroke: white;
	}

  .posts-grid {
    &__item {
      background: $darkLighter;

      h3,
      p {
        color: $white;
			}
		}
		
		.external-link__icon {
			stroke: $whiteLighter;
		}

	}

	
	.footer {
		background: $darkLighter;
	}

  .dark-mode-toggle {
		background: $white;

		& > button {
			color: #999;
			&:last-child {
				color: $moonColor;
			}
		}
	}
}

// toggle style
.dark-mode-toggle {
  display: flex;
	margin: 0 auto;
	flex-direction: column;
	
	position: fixed;
	right: 0;
	top: 23px;
	background-color: $darkLighter;
	z-index: 999;
	border-radius: 4px 0px 0px 4px;
	transition: .3s ease all;

  & > button {
    font-size: 1.5em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:last-child {
      color: #666;
    }

    &:focus {
      outline: none;
		}
		
	}
}

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

input[type="checkbox"].dmcheck {
  width: 40px;
  height: 10px;
  background: #555;
  position: relative;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 30px;
  }

  &:focus-visible {
    outline: solid 2px white;
  }

  & + label {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    left: 2px;
    background: #fff;
    opacity: 0.9;
    background-color: #f6f6f6;
  }
}
