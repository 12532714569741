// breakpoints
$lg: 1170px;
$md: 1024px;
$sm: 640px;
$xs: 480px;

// brand colors
$main-color: #de1186; // pink
$secondary-color: #151493; //dark blue
$optional-color: #ffc338; // yellow

$red: #de1186;
$bg-primary: #eff0f3;

// height
$height-80: 80vh;

// colors
$main-dark: #001f3f;

// shadow
$box-shadow: 0 3px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
$box-shadow-hover: 6px 6px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

// transitions
$transition: all 0.2s ease-in-out;
$transitionSlower: all 0.4s ease-in-out;

// paddings
$box-padding: 1.25rem;

// borders
$border-radius: 4px;