@import "../../variables";

.sidebar-width {

	@media (min-width: $sm) {
		min-width: 270px;
	}
}

aside {
  background: white;
  padding: 20px;
  position: sticky;
  top: 23px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  .item {
		width: 100%;
		color: blue !important;

    &__inner {
      display: flex;
      align-items: center;
			padding: 8px;
			transition: $transition;
			width: 100%;
			background: transparent;
			border: 0;
			cursor: pointer;

			&:hover {
				background: $red;
				box-shadow: $box-shadow;
	
				.item__icon {
					margin-left: 4px;
				}
	
				.item__name {
					color: white;
				}
			}
		}

    &__icon {
			margin-right: 12px;
			transition: $transition;
			width: 30px;
			height: 30px;
    }

    &__name {
			font-family: inherit;
			font-size: 16px;
			color: #333;
			transition: $transition;
		}
		
	}

	.active-link {
		.item__inner {
			background: $red;
			box-shadow: $box-shadow;

			.item__icon {
				margin-left: 4px;
			}

			.item__name {
				color: white;
			}			
		}
	}
	
	// aside media
	@media only screen and (max-width: $sm ) {
		.sidebar-container {
			display: flex;
			overflow: auto;
			align-items: center;
			
			.item__icon {
				display: none;
			}
		}
	}

	@media only screen and (min-width: $sm ) {
		margin: 23px 0 20px 20px;
	}

	
}
