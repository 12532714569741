@import "../../variables";

.header {
	text-align: center;

	.dark-mode-toggle {
		position: absolute;
		right: 7px;
		top: 5px;
	}

	.sidebar-heading {
		padding-bottom: 8px;
	
		h1 {
			font-size: 1.2rem;
			color: $red;
		}
	}
}

.logo {
	max-width: 55px;
	height: 55px;
	width: 55px;

	.sky {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: $optional-color;
	}

	.waves {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: none;
		stroke: $secondary-color;
		stroke-width: 2;
		stroke-linecap: round;
		stroke-miterlimit: 3;
	}

	.border {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: none;
		stroke: $main-color;
		stroke-width: 7;
		stroke-linecap: round;
		stroke-miterlimit: 3;
	}
}