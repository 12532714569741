@import "../../variables";

.grid-item {
	display: grid;
	position: relative;
	
	&:hover {
		.external-link {
			display: inline;
		}
	}
}

.posts-grid {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

	&__item {
		position: relative;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		padding: $box-padding;
		background: white;
		transition: $transition;

		.post-icon {
			width: 45px;
			height: 45px;
			object-fit: contain;
		}

		.post-title {
			color: initial;
			font-size: 1.2rem;
			font-weight: 700;
			margin-bottom: 8px;

			&::after {
				content: "";
				display: block;
				width: 30px;
				height: 3px;
				border-radius: $border-radius;
				background: $red;
				transition: $transitionSlower;
			}
		}

		.post-description {
			color: #4e4e4e;
		}

		// __item hover
		&:hover {
			box-shadow: $box-shadow-hover;

			.post-title {

				&::after {
					width: 40px;
				}
			}
		}

	}
}